.discount {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .tabContainer {
    border-bottom: 1px solid #00000029;
  }
  .container {
    flex: 1;
    overflow-y: auto;
  }
}
.toolbar {
  display: flex;
  margin: 13px 15px 2px 15px;
  justify-content: space-between;
}
.filters {
  display: flex;
  gap: 4px;
  button {
    display: flex;
    align-items: center;
    padding: 8px 16px 7px 16px;
    border-radius: 18px;
    border: 1px solid #cbcbcb;
    height: 35px;
    color: #cbcbcb;
    background: white;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    &.active {
      border-color: #000000;
      background: #00f892;
      color: black;
    }
    &:disabled {
      border-color: #cbcbcb;
      background: #e7e7ea;
      color: #999;
      cursor: initial;
    }
  }
}
.sort {
  button {
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #303030;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    gap: 3px;
  }
}
.list {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .listItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 14px;
    background: white;
    border: 1px solid #707070;
    border-radius: 6px;
    gap: 14px;
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-top-left-radius: 22px;
      border-bottom-left-radius: 22px;
      border: 1px solid #707070;
      border-right: 0;
      background: white;
      box-shadow: #00000029 5px 7px inset;
      right: -1px;
      display: block;
      width: 11px;
      height: 22px;
    }
    .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: #00000029;
      border-radius: 6px;
      left: 6px;
      top: 8px;
      &:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
        border: 1px solid transparent;
        border-right: 0;
        background: white;
        right: -1px;
        display: block;
        width: 11px;
        height: 22px;
      }
    }
    .img {
      width: 60px;
      height: 60px;
      background: #ffad22;
      border-radius: 6px;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex: 1;
      h5 {
        margin: 0;
        font-weight: 500;
        font-size: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .date {
        font-size: 14px;
        color: #999999;
      }
      .code {
        font-size: 10px;
        font-weight: 500;
        margin-top: 6px;
      }
      button {
        color: #999999;
        border: 1px solid #999999;
        border-radius: 11px;
        background: white;
        cursor: pointer;
        width: 72px;
        padding: 0 5px;
        margin-top: 5px;
        font-size: 11px;
        display: inline-flex;
        align-items: center;
        span {
          flex: 1;
        }
        svg {
          width: 11px;
          height: 11px;
        }
        & + span {
          color: #999999;
          font-size: 9px;
          margin-left: 8px;
        }
        &:disabled {
          background: #e7e7ea;
          color: #999;
          cursor: initial;
        }
      }
    }
    .infoBtn {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      padding: 0;
    }
    .btnContainer {
      position: relative;
      z-index: 1;
      background: #00f892;
      border-radius: 6px;
      height: 30px;
      width: 60px;
      // margin-left: 4px;
      margin-right: 10px;
      button {
        background: none;
        border: none;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
        height: 100%;
        color: black;
        padding: 0;
        &:disabled {
          background: #e7e7ea;
          color: #999;
          cursor: initial;
        }
      }
    }
  }
}
.dialogHeader {
  margin-bottom: 20px;
  h5 {
    color: #077e4c;
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 18px;
  }
  h6 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    line-height: 20px;
  }
  .timer {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 10px;
    p {
      font-size: 36px;
      font-weight: bold;
      margin-top: 66px;
      margin-bottom: 42px;
    }
  }
  .authNum {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 10px;
    p {
      font-size: 36px;
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .authNumBottom {
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    line-height: 20px;
  }
}
.couponDetail {
  background: #f2f2f2;
  padding: 5px;
  height: calc(100vh - env(safe-area-inset-bottom));
  & > div {
    padding: 25px 20px;
    background: white;
    height: 100%;
    overflow: auto;
  }
  p {
    white-space: pre-wrap;
    font-size: 15px;
    color: #000000;
    line-height: 21px;
    margin: 0;
  }
}
