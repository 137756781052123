.profile {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  .avatar {
    position: relative;
    margin: 0 auto;
    margin-top: 25px;
    svg {
      width: 120px;
      height: 120px;
      &.edit {
        width: 20px;
        height: 20px;
        background-color: #00f892;
        border-radius: 100%;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }
  h3 {
    margin: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 17px;
    font-weight: normal;
  }
  .formContainer {
    flex: 1;
    width: 100%;
    background-color: white;
    border-radius: 48px 48px 0 0;
    .inner {
      margin: 0 auto;
      padding-top: 48px;
      width: 260px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .footer {
      margin: 0 auto;
      margin-top: 30px;
      width: 260px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      button {
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 16px;
        border-radius: 4px;
        color: #000000;
        height: 45px;
        transition: all 0.3s ease;
        &.save {
          background-color: greenyellow;
          color: black;
          cursor: initial;
        }
        &:disabled {
          color: #b6b6b6;
          background-color: #f2f2f2;
        }
        &.cancel {
          background-color: lightgray;
          color: black;
          cursor: initial;
        }
      }
      label {
        margin: 0;
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: normal;
        color: blue;
        text-align: center;
      }
    }
  }
}
