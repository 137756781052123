.menuTabBar {
  width: 100%;
  height: 100%;
  background-color: white;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;

  .menuItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: var(--menu-height);
    justify-content: center;
    color: #999;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    &.active {
      span {
        color: var(--tab-color);
      }
      svg {
        fill: var(--tab-color) !important;
      }
    }

    &:hover {
      span {
        color: var(--tab-color);
      }
      svg {
        fill: var(--tab-color) !important;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }
    span {
      margin-top: 3px;
      font-size: 10px;
    }
    svg {
      :global {
        .filled {
          display: none;
        }
      }
      &:hover {
        fill: var(--tab-color) !important;
      }
    }
  }
}
