.problem {
  background: #f2f2f2;
  padding: 5px;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
  & > div {
    padding: 25px 20px;
    background: white;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    align-self: flex-start;
  }
  p {
    font-size: 15px;
    color: #000000;
    line-height: 21px;
    margin: 0;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 63px;
    button {
      width: 260px;
      height: 48px;
      border: 1px solid #707070;
      border-radius: 6px;
      cursor: pointer;
      font-size: 17px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      background: #f2f2f2;
      box-shadow: #00000029 3px 4px;
      padding: 0;
      span {
        flex: 1;
      }
      &.uploaded {
        background: #ffad22;
        box-shadow: #00000029 3px 4px inset;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 130px;

    display: flex;
    justify-content: center;
    button {
      width: 150px;
      height: 48px;
      border: 1px solid #707070;
      border-radius: 6px;
      cursor: pointer;
      font-size: 17px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00f892;
      box-shadow: #00000029 3px 4px;
      color: black;
      padding: 0;
      span {
        flex: 1;
      }
    }
  }
}
