.nearby {
  position: relative;
  overflow: hidden;
  .infoModal {
    // position: absolute;
    // top: -142px;
    // left: -120px;
    // z-index: 1000;
    width: 260px;
    background: #ffffff;
    border-radius: 13px;
    border: 1px solid #707070;
    box-shadow: #00000029 3px 4px;
    padding: 16px 17px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    cursor: pointer;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .tagContainer {
        div {
          text-align: center;
          background: #00f892;
          border-radius: 11px;
          font-size: 9px;
          padding: 2px;
          width: 46px;
          color: #303030;
          font-weight: bold;
        }
        &.orange {
          div {
            background: #ffad22;
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 6px;
      margin-top: 0px;
      div {
        &:first-child {
          font-size: 11px;
          button {
            background-color: #ffad22;
          }
        }
        button {
          color: #303030;
          position: relative;
          background: #00f892;
          border: 1px solid #303030;
          border-radius: 6px;
          text-align: center;
          width: 110px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: #00000029 6px 8px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 17px;
          }
          > div {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
    .name {
      color: #303030;
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
    }
    .channel_name {
      color: #303030;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 2px;
    }
    .address,
    .opentime {
      font-size: 13px;
      color: #999999;
    }
    p {
      margin: 0;
    }
  }
}

.marker {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  outline: none;
  border: 1px solid #00f892;
  display: flex;
  gap: 3px;
  padding: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -29px;
    left: 3px;
    border: 15px solid transparent;
    border-top: 20px solid #00f892;
  }

  svg {
    width: 26px;
    height: 26px;
  }
  &.box {
    border-color: #00f892;
    background: #00f892;
    &:after {
      border-top-color: #00f892;
    }
  }
  &.staff {
    border-color: #ffad22;
    background: #ffad22;
    &:after {
      border-top-color: #ffad22;
    }
  }
}

.searchContainer {
  position: absolute;
  width: calc(100% - 34px);
  right: 17px;
  top: 20px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // left: calc(100% - 54px - 17px);
  transition: all 0.8s ease;
  visibility: hidden;

  .search {
    position: relative;
    height: 54px;
    .inputContainer {
      position: absolute;
      left: calc(100% - 54px);
      right: 0px;
      display: flex;
      height: 54px;
      border-radius: 27px;
      background: white;
      transition: left 0.8s ease;
      .autocompleteContainer {
        display: flex;
        flex: 1;
        align-items: center;
      }
      .buttonContainer {
        position: absolute;
        right: 0;
      }
      button {
        background: white;
        border: none;
        outline: none;
        width: 54px;
        height: 54px;
        border-radius: 100%;
        box-shadow: #00000029 3px 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .filters {
    visibility: hidden;
    opacity: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    row-gap: 10px;
    transition: all 0.5s ease;
  }
  .closeContainer {
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    transition: all 0.5s ease;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #303030;
      width: 40px;
      height: 40px;
      box-shadow: inset 3px 4px 0px #00000029;
      border: 1px solid #303030;
      border-radius: 20px;
      background-color: #ffad22;
      outline: none;
      cursor: pointer;
    }
  }
  &.active {
    left: 17px;
    visibility: visible;
    .search {
      .inputContainer {
        height: 56px;
        border: 1px solid #303030;
        left: 0px;
        box-shadow: inset 3px 4px 0px #00000029;
        button {
          box-shadow: none;
        }
        .buttonContainer {
          button {
            background: transparent;
          }
        }
      }
    }
    .filters,
    .closeContainer {
      opacity: 1;
      visibility: visible;
    }
  }
}
.searchButtonContainer {
  position: absolute;
  // width: calc(100% - 34px);
  right: 17px;
  top: 20px;
  z-index: 1001;
  button {
    background: white;
    border: none;
    outline: none;
    width: 54px;
    height: 54px;
    border-radius: 100%;
    box-shadow: #00000029 3px 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.active {
    button {
      box-shadow: none;
      background: transparent;
    }
  }
}

.toHomeContainer {
  position: absolute;
  right: 36px;
  bottom: 36px;
  z-index: 1001;
  display: flex;
  background: white;
  height: 54px;
  border-radius: 27px;
  gap: 5px;
  transition: left 0.8s ease;
  &.active {
    height: 56px;
    border: 1px solid #303030;
    left: 17px;
    box-shadow: #00000029 3px 4px inset;
    button {
      box-shadow: none;
    }
  }
  button {
    background: white;
    border: none;
    outline: none;
    width: 54px;
    height: 54px;
    border-radius: 100%;
    box-shadow: #00000029 3px 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.exclusive {
  overflow-y: auto;
  img {
    max-width: 100%;
    display: block;
  }
  .description {
    padding: 0 24px;
    padding-top: 31px;
    font-size: 15px;
    color: #000000;
    p {
      margin: 0;
    }
  }
}
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 145px;
}
.shopList {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
  .shopItem {
    height: 84px;
    width: 100%;
    box-shadow: 0px 1px 0px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px 16px 20px;
    gap: 18px;
    cursor: pointer;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      border-radius: 56px;
      &.box {
        background-color: #00f892;
      }
      &.staff {
        background-color: #ffad22;
      }
    }
    .shopDetail {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
      .tagContainer {
        div {
          text-align: center;
          background: #00f892;
          border-radius: 11px;
          font-size: 9px;
          padding: 2px;
          width: 46px;
          color: #303030;
          font-weight: bold;
        }
        &.orange {
          div {
            background: #ffad22;
          }
        }
      }
      > div {
        font-size: 11px;
        line-height: 11px;
        color: #999999;
        display: inline-block;
        &:first-child {
          display: flex;
          justify-content: space-between;
          width: 100%;
          h3 {
            margin: 0;
          }
          color: #303030;
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
}
