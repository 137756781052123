.adEmail {
  display: flex;
  gap: 3px;
  align-items: flex-start;
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 12px;
  padding: 0 8px;
  color: #999999;
  label {
    line-height: 15px;
  }
  input[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    position: relative;
    color: black;
    min-width: 10px;
    height: 10px;
    border: 1px solid black;
    margin-top: 3px;

    &::before {
      content: '';
      position: absolute;
      font-size: 12px;
      left: 1px;
      top: -3.5px;
    }
    &:checked {
      &::before {
        content: 'v';
      }
    }
  }
}
.button {
  position: relative;
  display: flex;
  align-items: center;
  width: 150px;
  height: 35px;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #707070;
  box-shadow: #00000029 6px 8px;
  background: #00f892;
  padding: 0;
  margin: 0 auto;
  color: black;
  cursor: pointer;
  &.rounded {
    border-radius: 23px;
    box-shadow: #00000029 4px 4px;
  }
  &:disabled {
    background: #e7e7ea;
    color: #999;
    cursor: initial;
  }
  &.disabled {
    background: #e7e7ea;
    color: black;
  }
  span {
    flex: 1;
  }
  svg {
    position: absolute;
    right: 0;
    width: 16px;
  }
}
