@mixin layout {
  display: flex;
  flex-direction: column;
  height: calc(100% - env(safe-area-inset-bottom));
  .footer {
    padding-top: 53px;
    padding-bottom: 37px;
    width: 100%;
    max-width: 414px;
  }
}

.paymentSetting {
  @include layout;
  padding-left: 16px;
  position: relative;
  overflow: hidden;
  h1 {
    display: inline-block;
    font-size: 19px;
    margin: 0;
    margin-top: 32px;
    font-weight: 500;
  }
  .container {
    position: relative;
    flex: 1;
    overflow: auto;
  }
  .paymentList {
    width: 100%;

    overflow: auto;
    > div {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #3c3c434a;
      button {
        border: none;
        width: 100%;
        height: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #303030;
        font-size: 12px;
        cursor: pointer;
        svg {
          margin-right: 22px;
        }
        span {
          text-align: left;
          flex: 1;
        }
        span + svg {
          margin-right: 0;
        }
      }
    }
  }
  .form {
    margin-top: 52px;
    margin-bottom: 55px;
    width: 100%;
    > div + div {
      margin-top: 30px;
    }
    .group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > div {
        flex: 1;
      }
      > div:nth-child(even) {
        margin-left: 9.5px;
      }
      > div:nth-child(odd) {
        margin-right: 9.5px;
      }
      &.fixWidth {
        margin-top: 0;
        flex-wrap: wrap;
        > div {
          margin-top: 24px;
          flex: none;
          width: calc(50% - 9.5px);
        }
      }
    }
  }
  .footer {
    position: absolute;
    left: 0;
    bottom: 45px;
    width: 100%;
    max-width: 414px;
  }
  .memberMethods {
    margin-top: 40px;
    padding: 0 20px;
    .methodList {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .method {
        display: flex;
        align-items: center;
        gap: 54px;
        > div {
          &:first-child {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            font-size: 15px;
          }
        }
        button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 83px;
          height: 35px;
          font-size: 15px;
          border-radius: 23px;
          border: 1px solid #707070;
          box-shadow: #00000029 6px 8px;
          background: #00f892;
          padding: 0;
          margin: 0 auto;
          color: black;
          cursor: pointer;
          &.disabled {
            background: #e7e7ea;
            color: #999;
          }
          span {
            flex: 1;
          }
        }
      }
    }
    .tips {
      padding: 0 14px;
      font-size: 14px;
    }
  }
}
.paymentDescription {
  @include layout();
  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 0 40px;
    padding-top: 62px;
    p {
      font-size: 14px;
      line-height: 19px;
    }
    .title {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 6px;
    }
  }
}
.linkLinePay {
  @include layout();
  padding: 0 30px;
  .container {
    flex: 1;
    p {
      font-size: 14px;
      line-height: 20px;
    }
    .title {
      margin-top: 52px;
      margin-bottom: 9px;
      font-size: 15px;
      line-height: 17px;
    }
    .description {
      padding: 0 10px;
      padding-bottom: 30px;
    }
    .paymentMethods {
      margin: 0 20px;
      border: 1px solid #000000;
      .header {
        background: #00f892;
        font-size: 13px;
        padding: 8px 18px;
        border-bottom: 1px solid #000000;
      }
      .method {
        margin: 20px 18px;
        display: flex;
        align-items: center;
        > div {
          &:first-child {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 46px;
            font-size: 13px;
          }
          input[type='radio'] {
            appearance: none;
            background-color: #fff;
            margin: 0;

            font: inherit;
            color: currentColor;
            width: 15px;
            height: 15px;
            border: 1px solid currentColor;
            border-radius: 50%;
            transform: translateY(-0.075em);

            display: grid;
            place-content: center;
            &::before {
              content: '';
              width: 7px;
              height: 7px;
              border-radius: 50%;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              box-shadow: inset 1em 1em black;
            }
            &:checked {
              &::before {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    .terms {
      display: flex;
      gap: 16px;
      align-items: center;
      font-size: 12px;
      margin-bottom: 12px;
      color: #999999;
      a {
        color: black;
      }
      input[type='checkbox'] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        position: relative;
        color: black;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        &::before {
          content: '';
          position: absolute;
          font-size: 15px;
          left: 5.5px;
          top: 0px;
        }
        &:checked {
          &::before {
            content: 'v';
          }
        }
      }
    }
  }
}
.linkResult {
  @include layout();
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      margin-top: 42px;
      margin-bottom: 31px;
    }
    .info {
      padding: 0 49px;
      width: 100%;

      .inner {
        display: flex;
        border: 1px solid #303030;
        .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #00f892;
          border-right: 1px solid #303030;
          > div {
            padding: 0 13px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              font-size: 15px;
              font-weight: bold;
            }
          }
        }
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          > div {
            padding: 0 13px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
              font-size: 10px;
            }
            &:first-child {
              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.dialogHeader {
  margin-top: 53px;
  margin-bottom: 15px;
}
.okButton {
  background: transparent;
  color: black;
}

:global {
  .fade-enter {
    position: absolute;
    opacity: 0;
    transform: translateX(100%);
    z-index: 1;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 250ms ease-out, transform 300ms ease;
  }
  .fade-exit {
    position: absolute;
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-out, transform 300ms ease;
  }
}
