.register {
  position: relative;
  height: calc(100vh - env(safe-area-inset-bottom));
  .backgroundImg {
    img {
      width: 100%;
      display: block;
    }
  }
  .registerForm {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    text-align: center;
    h5 {
      font-size: 17px;
      font-weight: 500;
      margin-top: 9px;
      margin-bottom: 17px;
    }
    .form {
      margin-top: 0;
      margin-bottom: 55px;
      width: 100%;
      > div + div {
        margin-top: 24px;
      }
    }
    .privacy {
      margin-top: 31px;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      a {
        color: #000000;
      }
    }
  }
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  height: 45px;
  outline: none;
  background-color: var(--theme-color);
  font-weight: bold;
  color: white;
  font-size: 17px;
  // margin-top: 35px;
  transition: opacity 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.dialogHeader {
  margin-top: 20px;
  margin-bottom: 28px;
  h5 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 7px;
    font-weight: 600;
  }
  p {
    margin: 0;
    font-weight: 14px;
  }
}
