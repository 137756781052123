.button {
  position: relative;
  display: flex;
  align-items: center;
  width: 240px;
  height: 64px;
  font-size: 24px;
  border-radius: 6px;
  border: 1px solid #707070;
  box-shadow: #00000029 6px 8px;
  background: #00f892;
  padding: 0;
  margin: 0 auto;
  color: black;
  cursor: pointer;
  &:disabled {
    background: #e7e7ea;
    color: #999;
    cursor: initial;
  }
  span {
    flex: 1;
  }
  svg {
    position: absolute;
    right: 0;
  }
}
