@import '../../Variables.module.scss';
.home {
  background-color: #00f892;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  .group {
    display: flex;
    gap: 20px;
    .menu {
      flex: 1;
    }
  }
  .banner {
    img {
      display: block;
      max-width: 100%;
    }
  }
  .menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: #00000026 6px 8px;
    padding: 18px 21px 18px 24px;
    min-height: 110px;
    font-size: 24px;
    cursor: pointer;
    background: white;
    margin: 0 20px;
    margin-top: 18px;
    span {
      font-weight: 600;
    }
    &.column {
      flex-direction: column;
      justify-content: center;
      font-size: 17px;
      > div {
        display: flex;
        align-items: center;
      }
    }
    &.disabled {
      cursor: initial;
      box-shadow: #00000029 6px 8px inset;
      background: #cbcbcb;
      color: #3030304d;
    }
    .lock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
