.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  height: 57px;
  outline: none;
  border-radius: 4px;
  background-color: var(--theme-color);
  font-weight: bold;
  color: white;
  font-size: 16px;

  // margin-top: 35px;
  transition: opacity 0.3s ease;
  cursor: pointer;
  &.cancel {
    background-color: transparent;
    color: black;
  }
  &:hover {
    opacity: 0.7;
  }
}
