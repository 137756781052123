.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  &.checked {
    border: none;
    background-color: #00f892;
    svg {
      width: 100%;
      color: white;
    }
  }
}
