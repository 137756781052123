.privacy {
  background: #f2f2f2;
  padding: 5px;
  padding-bottom: env(safe-area-inset-bottom);
  overflow: hidden;
  & > div {
    padding: 25px 20px;
    background: white;
    height: 100%;
    overflow: auto;
  }
  p {
    white-space: pre-wrap;
    font-size: 15px;
    color: #000000;
    line-height: 21px;
    margin: 0;
  }
}
