.sheet {
  z-index: 10000;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0px -4px 0px #00000029;
  background: #fff;
  touch-action: none;
  display: flex;
  flex-direction: column;
  .headerContainer {
    overflow: hidden;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 11px;
    gap: 2px;
    height: 40px;
    button {
      padding: 0;
      color: #000000;
      display: flex;
      align-items: center;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        width: 22px;
      }
    }
  }
  .body {
    overflow: hidden;
    flex: 1;
    &.scrollable {
      overflow-y: auto;
    }
  }
}
